import ClientSearch from "../../search/clients/client-search-form";
import TaxableAccountsSearchForm from "../../search/accounts/taxable-accounts-search-form";
import TaxableAccountsSearchResultsRow from "../../search/accounts/taxable-accounts-search-results-row";
import useGainsLossTabState from "../../state/useGainsLossTabState";
import useSharedContext from "../../state/useSharedContext";
const GainsLossTab = () => {
  const { taxableAccountsSearchCompleteState } = useGainsLossTabState();
  const { context } = useSharedContext();
  return (
    <>
      <div className="row">
        <div className="col-6">
          <div
            className="border border-secondary rounded p-3 bg-light"
            style={{ height: "400px" }}
          >
            <ClientSearch context={context} />
            <TaxableAccountsSearchForm />
          </div>
        </div>
        <div className="col-6">
          <div
            className="border border-secondary rounded p-3 bg-light"
            style={{ height: "400px" }}
          >
            <h6>Taxable Accounts</h6>

            {taxableAccountsSearchCompleteState && (
              <TaxableAccountsSearchResultsRow />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GainsLossTab;

import { useState } from "react";

const useDownloadingState = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingMessage, setDownloadingMessage] = useState("");

  return {
    isDownloading,
    setIsDownloading,
    downloadingMessage,
    setDownloadingMessage,
  };
};

export default useDownloadingState;

import useSharedDownloadingState from "./useSharedDownloadingState";

export const useFileDownloader = () => {
  const { setIsDownloading } = useSharedDownloadingState(false);

  const downloadDocument = (url, fileName) => {
    setIsDownloading(true);
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.withCredentials = true;
    req.responseType = "blob";
    req.onload = function () {
      const blob = req.response;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsDownloading(false);
    };

    req.send();
  };

  return { downloadDocument };
};

import React from "react";
import CurrencyFormat from "react-currency-format";

const UnrealizedGainsLossView = ({ account }) => {
  return (
    <>
      <header className="row" role="row">
        <div className="col-3" role="gridcell"></div>
        <div className="col-3" role="gridcell">
          Value as of{" "}
          {new Date(
            Date.parse(account.unrealizedGainLoss.priceDate)
          ).toLocaleDateString("en-US")}
        </div>
        <div className="col-3" role="gridcell">
          Cost Basis
        </div>

        <div className="col-3" role="gridcell">
          Unrealized Gain or Loss
        </div>
      </header>
      <div className="row data-row" role="row" aria-label="Short Term Gain">
        <header className="col-3" role="gridcell">
          Short Term Gain
        </header>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedShortTermGainValue}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedShortTermGainCostBasis}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedShortTermGain}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      </div>
      <div className="row data-row" role="row" aria-label="Short Term Loss">
        <header className="col-3" role="gridcell">
          Short Term Loss
        </header>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedShortTermLossValue}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedShortTermLossCostBasis}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedShortTermLoss}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      </div>
      <div className="row data-row" role="row" aria-label="Long Term Gain">
        <header className="col-3" role="gridcell">
          Long Term Gain
        </header>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedLongTermGainValue}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedLongTermGainCostBasis}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedLongTermGain}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      </div>
      <div className="row data-row" role="row" aria-label="Long Term Loss">
        <header className="col-3" role="gridcell">
          Long Term Loss
        </header>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedLongTermLossValue}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedLongTermLossCostBasis}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.unrealizedLongTermLoss}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      </div>
      <div className="row totals" role="row" aria-label="Totals">
        <header className="col-3" role="gridcell">
          Net Totals
        </header>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.totalUnrealizedValue}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.totalUnrealizedCostBasis}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.unrealizedGainLoss.totalUnrealizedNetGainLoss}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      </div>
      {account.unrealizedGainLoss.missingOrOutOfDatePriceTickers.length > 0 && (
        <div className="row" role="row">
          <aside className="col-12 disclosure">
            {" "}
            We are unable to retrieve a price for the following tickers:&nbsp;
            {[].concat(
              account.unrealizedGainLoss.missingOrOutOfDatePriceTickers
            )}
          </aside>
        </div>
      )}
    </>
  );
};

export default UnrealizedGainsLossView;

import { useState } from "react";

// Make a custom hook with your future shared state
const useUserSession = () => {
  const [active, setActive] = useState(false);
  const [userName, setUserName] = useState("Not Logged In");

  return {
    active,
    setActive,
    userName,
    setUserName,
  };
};

export default useUserSession;

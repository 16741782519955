import axios from "axios";

const TaxStatementsList = async (selectedClientId) => {
  const searchUrl = `${process.env.REACT_APP_TAX_CENTER_API_URL}/taxcenter/documents/${selectedClientId}/`;

  return await axios
    .get(`${searchUrl}`, {
      withCredentials: true,
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export default TaxStatementsList;

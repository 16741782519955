import "./gains-loss.css";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import useSharedMessenger from "../../state/useSharedMessenger";
import useGainsLossTabState from "../../state/useGainsLossTabState";
import useSharedDownloadingState from "../../common/useSharedDownloadingState";

import GainsLossApi from "./gains-loss-search-api";
import GainslossAccountTitleFormat from "./gains-loss-account-title-format";
import {
  CheckbackLaterMessage,
  DataUnavailableMessage,
} from "./gains-loss-messages";
import { ApplyAccountStyling } from "./show-hide-toggler";
import GainsLossNavbar from "./gains-loss-navbar";
import UnrealizedGainsLossView from "./gains-loss-unrealized-view";
import RealizedGainsLossView from "./gains-loss-realized-view";
import GainsLossDisclosure from "./gains-loss-disclosure";
import DocumentDownloadSpinner from "./gains-loss-spinner";

function GainsLossView(props) {
  /** local state */
  const [gainsLossSearchStartState, setGainsLossSearchStartState] =
    useState(false);

  const [gainsLossSearchCompleteState, setGainsLossSearchCompleteState] =
    useState(false);

  const [gainsLoss, setGainsLoss] = useState({});
  const [errorState, setErrorState] = useState(false);

  /** shared state */
  const {
    toggleGainsLossModal,
    setToggleGainsLossModal,
    gainsLossSelectedAccount,
    setGainsLossSelectedAccount,
    gainsLossSelectedAccountStyleClass,
  } = useGainsLossTabState();

  const { setMessage } = useSharedMessenger();
  const { isDownloading, setIsDownloading, setDownloadingMessage } =
    useSharedDownloadingState();

  useEffect(() => {
    if (errorState) {
      setGainsLossSearchCompleteState(false);
      setGainsLossSearchStartState(false);
      setToggleGainsLossModal(false);
      setIsDownloading(false);
      setErrorState(false); //reset for the next one!
    }
  }, [errorState, setIsDownloading, setToggleGainsLossModal]);

  useEffect(() => {
    if (!gainsLossSearchCompleteState) {
      setDownloadingMessage(
        `Retrieving Gains Loss information for account ${props.accountId}. This may take up to 20 seconds`
      );
      setIsDownloading(true);
    }
  }, [
    gainsLossSearchCompleteState,
    props.accountId,
    setIsDownloading,
    setDownloadingMessage,
  ]);

  const handleClose = () => {
    setToggleGainsLossModal(false);
    setGainsLossSearchCompleteState(false);
    setGainsLossSearchStartState(false);
    setGainsLoss({});
    setMessage({
      text: "",
      level: "",
    });
  };

  const fetchGainsLoss = async () => {
    setGainsLossSearchCompleteState(false);
    setGainsLossSearchStartState(true);
    setGainsLossSelectedAccount(props.accountId);
    GainsLossApi(props.clientId, props.accountId)
      .then((response) => {
        console.log("done fetching gains loss");

        const inputArgs = {
          ...response.data,
          SelectedAccount: props.accountId,
        };
        console.log(response.data);
        setGainsLoss(inputArgs);
        setGainsLossSearchCompleteState(true);
        setGainsLossSearchStartState(false);
        setIsDownloading(false);
      })
      .catch((error) => {
        setErrorState(true);
        console.log(error.message);
        if (error.message === "Network Error") {
          var log = "Tax center session expired. Please Login.";
          console.log(log);
          setMessage({
            text: `${log}`,
            level: "sessionexpired",
          });
        }
        if (error.response) {
          setMessage({
            text: "Error retrieving gains loss information for this client. Please try again",
            level: "danger",
          });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  useEffect(() => {
    if (
      toggleGainsLossModal === true &&
      props.accountId.length > 1 &&
      props.clientId > 0 &&
      gainsLossSearchStartState === false &&
      gainsLossSearchCompleteState === false
    ) {
      fetchGainsLoss();
    }
  });

  return (
    <main id="mainContent" className="gain-loss-content" role="main">
      <Modal
        data-test="master-gains-loss-modal"
        id="master-gains-loss-modal"
        key="456"
        show={toggleGainsLossModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <Modal.Header closeButton style={{ backgroundColor: "#f2f3f4" }}>
          <Modal.Title></Modal.Title>
          {gainsLossSearchCompleteState && <GainsLossNavbar {...gainsLoss} />}
        </Modal.Header>
        <Modal.Body>
          {isDownloading && <DocumentDownloadSpinner />}

          {gainsLossSearchCompleteState && (
            <>
              <div>
                <header className="text-left" role="banner">
                  <h2>{gainsLoss.name}</h2>
                  <h3>Gains &amp; Losses</h3>
                </header>

                {gainsLoss.accounts.map((account, index) => {
                  const titleFormatArgs = {
                    account: account,
                    selectedaccount: gainsLossSelectedAccount,
                  };
                  return (
                    <section
                      className={`account ${
                        account.accountNumber
                      } ${ApplyAccountStyling(
                        account.accountNumber,
                        gainsLossSelectedAccount
                      )}`}
                      role="document"
                      aria-label="Account Gains and Losses"
                      style={{ pageBreakInside: "avoid" }}
                      key={`section_agl_${account.accountNumber}_${index}`}
                    >
                      <GainslossAccountTitleFormat {...titleFormatArgs} />
                      <article
                        aria-label="Gains and Losses Summary"
                        key={`article_gls_${account.accountNumber}_${index}`}
                      >
                        {(account.unrealizedGainLoss.openPositions === 0 ||
                          account.unrealizedGainLoss.isError) &&
                        (account.realizedGainLoss.taxLotCount === 0 ||
                          account.realizedGainLoss.isErroi) ? (
                          <DataUnavailableMessage />
                        ) : (
                          <>
                            <section
                              className={`unrealized-gain-loss ${ApplyAccountStyling(
                                account.accountNumber,
                                gainsLossSelectedAccount
                              )}`}
                              aria-label="Unrealized Gains and Losses"
                              style={{ pageBreakInside: "avoid" }}
                              key={`section_ugl_${account.accountNumber}_${index}`}
                            >
                              <h5
                                className="text-left"
                                key={`h5_ugl_${account.accountNumber}_${index}`}
                              >
                                Unrealized Gains &amp; Losses
                              </h5>
                              {account.unrealizedGainLoss.openPositions > 0 &&
                              !account.unrealizedGainLoss.isError ? (
                                <UnrealizedGainsLossView account={account} />
                              ) : !account.unrealizedGainLoss.isError ? (
                                <DataUnavailableMessage />
                              ) : (
                                <CheckbackLaterMessage />
                              )}
                            </section>

                            <section
                              className={`realized-gain-loss ${gainsLossSelectedAccountStyleClass}`}
                              aria-label="realized Gains and Losses"
                              style={{ pageBreakInside: "avoid" }}
                              key={`section_rgl_${account.accountNumber}_${index}`}
                            >
                              <h5
                                className="text-left"
                                key={`h5_rgl_${account.accountNumber}_${index}`}
                              >
                                {account.realizedGainLoss.taxYear} Realized
                                Gains &amp; Losses
                              </h5>
                              {account.realizedGainLoss.taxLotCount > 0 &&
                              !account.realizedGainLoss.isError ? (
                                <RealizedGainsLossView account={account} />
                              ) : !account.unrealizedGainLoss.isError ? (
                                <DataUnavailableMessage />
                              ) : (
                                <CheckbackLaterMessage />
                              )}
                            </section>
                          </>
                        )}
                      </article>
                    </section>
                  );
                })}
                {gainsLoss.accounts.length > 0 ? (
                  <GainsLossDisclosure />
                ) : (
                  <p className="alert alert-info">
                    No open, taxable accounts were found.
                  </p>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
}

export default GainsLossView;

import axios from "axios";

const ClientList = async (query) => {
  const searchUrl = `${process.env.REACT_APP_TAX_CENTER_API_URL}/taxcenter/advisorclientsearch/`;

  console.log(`${searchUrl}?s=${query}`);
  return await axios
    .get(`${searchUrl}?s=${query}`, {
      withCredentials: true,
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export default ClientList;

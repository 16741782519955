const WrapLoginLink = (message) => {
  return (
    <a
      href={process.env.REACT_APP_TAMP_LOGIN_URL}
      target="_blank"
      rel="noreferrer"
    >
      {message}
    </a>
  );
};

export default WrapLoginLink;

import { useState } from "react";

const useMessenger = () => {
  const [message, setMessage] = useState({
    text: "",
    level: "",
  });

  return {
    message,
    setMessage,
  };
};

export default useMessenger;

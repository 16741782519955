import axios from "axios";

const GainsLoss = async (selectedClientId, selectedAccountId) => {
  const searchUrl = `${process.env.REACT_APP_TAX_CENTER_API_URL}/client/${selectedClientId}/account/${selectedAccountId}/gain-loss-2`;
  console.log(searchUrl);
  return await axios
    .get(`${searchUrl}`, {
      withCredentials: true,
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export default GainsLoss;

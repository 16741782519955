import React, { useEffect } from "react";
import _ from "lodash";

import { Accordion } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

import { useFileDownloader } from "../../common/useFileDownloader";
import { useFileNameGenerator } from "../../common/useFileNameGenerator";

import "./tax-statements-search.css";
import TaxFormNameMapping from "../../common/taxform-name-mapper";

/** state libs */
import useTaxStatementsTabState from "../../state/useTaxStatementsTabState";
import useSharedMessenger from "../../state/useSharedMessenger";
import useSharedDownloadingState from "../../common/useSharedDownloadingState";

const TaxStatementsSearchResultsRow = () => {
  const { taxDocuments } = useTaxStatementsTabState();
  const { setMessage } = useSharedMessenger();
  const { isDownloading } = useSharedDownloadingState();
  const { downloadDocument } = useFileDownloader();
  const { generateDocumentFileName } = useFileNameGenerator();

  const taxDocumentsGroupedByYear = taxDocuments.map((documents) =>
    _.groupBy(documents, "year")
  );

  const handleDownload = (message, url, documentType, isSummary) => {
    url = `${process.env.REACT_APP_TAX_CENTER_API_URL}/` + url;

    setMessage({
      text: message,
      level: "info",
    });

    const fileName = generateDocumentFileName(url, documentType, isSummary);

    setMessage({
      text: message,
      level: "info",
    });

    url = url.concat(`/${isSummary}`);

    downloadDocument(url, fileName);
  };

  useEffect(() => {
    if (!isDownloading) {
      setMessage({
        text: "Download complete",
        level: "success",
      });
    }
  }, [isDownloading, setMessage]);

  const summaryToolTip =
    "In most cases, the summary should be all that is needed to file your taxes.<br>" +
    "Due to the complexity of tax regulations and the individual circumstances<br> of our customers, we recommend that you consult a tax advisor.";

  return (
    <div className="row">
      <div className="col input-group mt-1">
        <div
          className="list-group collapse show align-right"
          id="tax-statement-list"
        >
          <Accordion defaultActiveKey="0" flush key="accordion_1">
            {taxDocumentsGroupedByYear.map((documents, i) => (
              <Accordion.Item eventKey={i} key={`accordion_item_${i}`}>
                <Accordion.Header key={`accordion_header_${i}`}>
                  {Object.keys(documents).toString()}{" "}
                </Accordion.Header>
                <Accordion.Body key={`accordion_body_${i}`}>
                  {documents[Object.keys(documents)].map((document, j) => (
                    <React.Fragment key={`fragment_${i}_${j}`}>
                      <h5
                        className="card-title"
                        key={`h5_card_title_${i}_${j}`}
                      >
                        {(j += 1)}. {TaxFormNameMapping(document.documentType)}
                      </h5>
                      {document.documentType === "Tax1099" && (
                        <>
                          <ReactTooltip
                            id="summaryButtonTip"
                            place="top"
                            effect="solid"
                            html={true}
                            key={`tooltip_sb_${i}_${j}`}
                          >
                            {summaryToolTip}
                          </ReactTooltip>
                          <button
                            className="btn tax-statement-summary-button"
                            data-test="tax-statement-summary-btn"
                            data-tip
                            data-for="summaryButtonTip"
                            key={`button_tdsb_${i}_${j}`}
                            onClick={() => {
                              handleDownload(
                                "Generating your summary tax statement",
                                document.location,
                                document.documentType,
                                true
                              );
                              return false;
                            }}
                          >
                            Summary
                          </button>
                        </>
                      )}
                      <button
                        className="btn bg-secondary tax-statement-detail-button"
                        type="button"
                        key={`button_tddb_${i}_${j}`}
                        onClick={() => {
                          handleDownload(
                            "Generating your detailed tax statement",
                            document.location,
                            document.documentType,
                            false
                          );
                          return false;
                        }}
                      >
                        Detail
                      </button>
                    </React.Fragment>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default TaxStatementsSearchResultsRow;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";

function TaxSoftwareVendor(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleContinue = (e) => {
    e.preventDefault();
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = props.vendorUrl;
    a.click();
    handleClose();
  };

  return (
    <>
      <img
        src={props.imageSource}
        width={props.width}
        height={props.height}
        alt={props.vendorUrl}
        onClick={handleShow}
        data-test={props.vendorName}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notice About Third Party Websites</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are now leaving the EQIS Capital Management, Inc.
            (&quot;EQIS&quot;) website. EQIS is not responsible for the content
            or availability of the destination website. EQIS is not an agent for
            the third-party website nor do we endorse, approve or guarantee its
            products, services or representations. The website is provided for
            convenience only and is not under the control of EQIS. The security
            and privacy policies of the third-party website may be different
            from EQIS’s{" "}
            <a
              href="https://pages.eqis.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="https://pages.eqis.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            . Contact the external website for answers to questions regarding
            its content.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            data-test="vendor-cancel-button"
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleContinue}>
            Continue to {props.vendorName}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TaxSoftwareVendor;

import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import {
  ApplyNavbarExportTaxLotsStyling,
  ApplyNavbarPdfButtonStyling,
} from "./show-hide-toggler";
import useGainsLossTabState from "../../state/useGainsLossTabState";
import { useFileDownloader } from "../../common/useFileDownloader";
import useSharedDownloadingState from "../../common/useSharedDownloadingState";

const GainsLossNavBar = (gainsLoss) => {
  /** shared state */
  const { setDownloadingMessage, setIsDownloading } =
    useSharedDownloadingState();
  const { downloadDocument } = useFileDownloader();

  const {
    gainsLossSelectedAccount,
    setGainsLossSelectedAccount,
    setGainsLossSelectedAccountStyleClass,
  } = useGainsLossTabState();

  const handleAccountChange = () => {
    const accountList = document.getElementById("gains-loss-account-list");

    let updatedSelectedAccount =
      accountList.options[accountList.options.selectedIndex].value;
    if (updatedSelectedAccount === "All Accounts") {
      setGainsLossSelectedAccountStyleClass("selected");
    } else {
      setGainsLossSelectedAccountStyleClass("");
    }
    setGainsLossSelectedAccount(updatedSelectedAccount);
  };

  const handleDownload = (message, url, fileName) => {
    url = `${process.env.REACT_APP_TAX_CENTER_API_URL}${url}`;
    setDownloadingMessage(message);
    setIsDownloading(true);
    downloadDocument(url, fileName);
  };

  const generateToolTip = (lastYearTaxLotsAvailableFlag) => {
    const thisYear = new Date().getFullYear();

    return lastYearTaxLotsAvailableFlag
      ? "Download Closed Tax Lots"
      : `Download Closed Tax Lots<br/>(${
          thisYear - 1
        }. Closed Tax Lots will be generated and available<br/> for download at the end of February ${thisYear})`;
  };

  /** because tooltips are bound to elements with dynamic identifiers ex id=`foo${bar}`,
   * we need to rebuild the tooltips to correctly bind. Workaround per https://github.com/wwayne/react-tooltip#readme
   *
   */
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <React.Fragment key={`fragment_navbar`}>
      <div className="btn-group" role="group">
        {gainsLossSelectedAccount === gainsLoss.allAccountsLabel && (
          <React.Fragment key="fragment_allaccounts">
            <ReactTooltip
              id="pdfToolTip_all"
              place="bottom"
              effect="solid"
              html={true}
            >
              {gainsLoss.pdfLink.title}
            </ReactTooltip>
            <button
              type="button"
              data-tip
              data-for="pdfToolTip_all"
              className="btn btn-primary account selected"
              key="button_pdf_download"
              onClick={() => {
                handleDownload(
                  "Creating your PDF document",
                  gainsLoss.pdfLink.url,
                  `${gainsLoss.name}-all-accounts-gain-loss.pdf`
                );

                return false;
              }}
            >
              {gainsLoss.pdfLink.label}
            </button>
          </React.Fragment>
        )}

        {gainsLoss.accounts.map((account, index) => {
          const elemId = `${account.accountNumber}_${index}_${Math.floor(
            Math.random() * 100 + 1
          )}`;
          let pdfFileName = `${gainsLoss.name}-${account.accountNumber}-gain-loss.pdf`;
          let openTaxLotsFileName = `${gainsLoss.name}-${account.accountNumber}-open-tax-lots.csv`;

          return (
            <React.Fragment key={`fragment_${account.accountNumber}`}>
              <ReactTooltip
                id={`pdfToolTip_${account.accountNumber}`}
                place="bottom"
                effect="solid"
                html={true}
              >
                {account.pdfLink.title}
              </ReactTooltip>
              <ReactTooltip
                id={`csvToolTip_${elemId}`}
                place="bottom"
                effect="solid"
                html={true}
              >
                {account.exportOpenTaxLotsLink.title}
              </ReactTooltip>
              <ReactTooltip
                key={`closedToolTip_${elemId}`}
                id={`closedToolTip_${elemId}`}
                place="bottom"
                effect="solid"
                html={true}
              >
                {generateToolTip(gainsLoss.areLastYearTaxLotsAvailable)}
              </ReactTooltip>

              {gainsLossSelectedAccount !== gainsLoss.allAccountsLabel && (
                <button
                  type="button"
                  data-tip
                  data-for={`pdfToolTip_${account.accountNumber}`}
                  className={`btn btn-primary account m-1 ${
                    account.accountNumber
                  } ${ApplyNavbarPdfButtonStyling(
                    account.accountNumber,
                    gainsLossSelectedAccount
                  )}`}
                  key={`button_pdf_download_${elemId}`}
                  onClick={() => {
                    handleDownload(
                      "Creating your PDF document",
                      account.pdfLink.url,
                      pdfFileName
                    );

                    return false;
                  }}
                >
                  {account.pdfLink.label}
                </button>
              )}

              {account.unrealizedGainLoss.openPositions > 0 &&
                !account.unrealizedGainLoss.isError && (
                  <button
                    type="button"
                    key={`button_ugl_${elemId}`}
                    id="open-tax-lots"
                    data-tip
                    data-for={`csvToolTip_${elemId}`}
                    className={`btn btn-primary account m-1 ${
                      account.accountNumber
                    } ${ApplyNavbarExportTaxLotsStyling(
                      account.accountNumber,
                      gainsLossSelectedAccount
                    )}`}
                    onClick={() => {
                      handleDownload(
                        "Creating your Open Tax Lots document",
                        account.exportOpenTaxLotsLink.url,
                        openTaxLotsFileName
                      );

                      return false;
                    }}
                  >
                    {account.exportOpenTaxLotsLink.label}
                  </button>
                )}

              {account.exportClosedTaxLotsLinks.length > 0 && (
                <div
                  key={`div_ectl_btn_group_${account.accountNumber}`}
                  role="group"
                  className={`btn-group account ${ApplyNavbarExportTaxLotsStyling(
                    account.accountNumber,
                    gainsLossSelectedAccount
                  )}`}
                >
                  <button
                    type="button"
                    key={`button_ectl_btn_group_${account.accountNumber}`}
                    data-tip
                    data-for={`closedToolTip_${account.accountNumber}`}
                    className={`btn btn-primary dropdown-toggle m-1`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Export Closed Tax Lots
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="closed-tax-lots"
                    key={`ul_ectl_${account.accountNumber}`}
                  >
                    {account.exportClosedTaxLotsLinks.map(
                      (exportLink, index) => {
                        const closedTaxLotsFileName = `${gainsLoss.name}-${account.accountNumber}-closed-tax-lots.csv`;
                        return (
                          <li
                            key={`li_ectl_exportlink_${account.accountNumber}_${index}`}
                          >
                            <button
                              key={`li_button_ectl_exportlink_${account.accountNumber}`}
                              className="dropdown-item"
                              data-toggle="tooltip"
                              data-placement="left"
                              title={exportLink.title}
                              onClick={() => {
                                handleDownload(
                                  `Creating your Closed Tax Lots document.`,
                                  exportLink.url,
                                  closedTaxLotsFileName
                                );
                                return false;
                              }}
                            >
                              {exportLink.label}
                            </button>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}
            </React.Fragment>
          );
        })}

        {gainsLoss.accounts.length > 1 && (
          <div
            key="div-accountlist-btn-group"
            role="group"
            className="btn-group"
          >
            <select
              id="gains-loss-account-list"
              className="btn btn-outline-secondary form-select"
              aria-label="Accounts"
              aria-labelledby="acctSelectLabel"
              onChange={handleAccountChange}
              value={gainsLossSelectedAccount}
              key="accounts_selector"
            >
              <option
                value={gainsLoss.selfLink.label}
                data-url={gainsLoss.selfLink.url}
                title={gainsLoss.selfLink.title}
                key="master_option"
              >
                {gainsLoss.selfLink.label}
              </option>
              {gainsLoss.accounts.map((account) => {
                return (
                  <React.Fragment key={`fragment_${account.accountNumber}`}>
                    <option
                      id={`accounts_option_${account.accountNumber}`}
                      value={account.accountNumber}
                      data-tip
                      data-for={`accounts_option_${account.accountNumber}`}
                      title={account.selfLink.title}
                      data-url={account.selfLink.url}
                      className="btn-group account"
                      key={`accounts_option_${account.accountNumber}`}
                    >
                      {account.selfLink.label}
                    </option>
                  </React.Fragment>
                );
              })}
            </select>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default GainsLossNavBar;

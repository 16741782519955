import React from "react";
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useFileDownloader } from "../common/useFileDownloader";

const TaxQuestions = () => {
  const { downloadDocument } = useFileDownloader();

  const taxSeasonGuideUrls = [
    `${process.env.REACT_APP_TAX_CENTER_API_URL}/taxcenter/download?fRef=1099-guide.pdf`,
    `${process.env.REACT_APP_TAX_CENTER_API_URL}/taxcenter/download?fRef=importing-data-into-tax-reporting-software.pdf`,
    `${process.env.REACT_APP_TAX_CENTER_API_URL}/taxcenter/download?fRef=K-1-help-sheet-2020.pdf`,
  ];

  const handleDownload = (url, fileName) => {
    downloadDocument(url, fileName);
  };

  return (
    <Accordion defaultActiveKey="0" flush className="pb-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header>TAX SEASON GUIDES</Accordion.Header>
        <Accordion.Body>
          <p>
            <FontAwesomeIcon icon={duotone("folder-arrow-down")} size="2x" />{" "}
            <button
              className="btn btn-link"
              data-test="help-sheet-link"
              onClick={() => {
                handleDownload(
                  taxSeasonGuideUrls[0],
                  "1099-help-sheet-link.pdf"
                );
                return false;
              }}
            >
              1099 Help Sheet
            </button>
          </p>
          <p>
            A guide to the consolidated 1099s different sections including
            1099-DIV, 1099-INT, 1099-OID,1099-MISC, and any supplemental
            information provided.
          </p>
          <p>
            <FontAwesomeIcon icon={duotone("folder-arrow-down")} size="2x" />{" "}
            <button
              className="btn btn-link"
              data-test="importing-data-link"
              onClick={() => {
                handleDownload(
                  taxSeasonGuideUrls[1],
                  "importing-data-tax-software.pdf"
                );
                return false;
              }}
            >
              Importing Data into Tax Reporting Software
            </button>
          </p>
          <p>
            A step-by-step guide on how to import 1099 information into a tax
            reporting program.
          </p>
          <p>
            <FontAwesomeIcon icon={duotone("folder-arrow-down")} size="2x" />{" "}
            <button
              className="btn btn-link"
              data-test="k1-help-sheet-link"
              onClick={() => {
                handleDownload(taxSeasonGuideUrls[2], "k1-help-sheet.pdf");
                return false;
              }}
            >
              K-1 Help Sheet
            </button>
          </p>
          <p>Answers to K-1 related questions.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header className="card">
          WHAT IS CONSOLIDATED FORM 1099?
        </Accordion.Header>
        <Accordion.Body>
          A consolidated Form 1099 is produced for taxable accounts and details
          the income associated with that account for a particular year. Our
          consolidated Form 1099 is a single document with sections for
          1099-DIV, 1099-INT, 1099-MISC, 1099-OID, and 1099-B. The first four
          1099 documents are found on page one of the consolidated Form 1099.
          These documents, along with the 1099-B pages (which list security
          sales and other proceeds) contain account data that is furnished to
          the IRS and must be included in your tax return.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          WHEN AND WHERE WILL I RECEIVE MY FORM 1099?
        </Accordion.Header>
        <Accordion.Body>
          Brokers are required by law to provide Form 1099 by February 15. Many
          firms, including Folio, have requested and been granted by the IRS a
          30 day extension to limit the number of corrected 1099 Forms required.
          Corrected 1099 Forms are typically necessary due to issuer changes to
          issuer provided information.Generally, 1099 Forms are posted around
          the end of February at Folio. Folio will send a notification once tax
          forms become available. You can find tax documents by logging into{" "}
          <a
            href="https://www.eqis.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.eqis.com
          </a>{" "}
          and navigating to your filing cabinet. The files will have an upload
          date of December 31 for the tax year they were created.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          WHY DOES THE CONSOLIDATED FORM 1099 CONTAIN SO MANY PAGES?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            Due to the complex nature of fractional shares, the length of Form
            1099 can extend into multiple pages as it lists each transaction.
            Clients are charged an annual Wrap Fee (the “Program Fee”) which
            covers the cost of administering the Program as well as the fees of
            the sub-advisers and certain custody fees. The Program Fee is
            calculated as a percentage of the average daily balance of the
            account and is paid monthly in arrears. Clients are encouraged to
            compare the information provided in their monthly performance
            reports against the information provided by the account custodian.
          </p>
          <p>
            By trading in dollar values, rather than share quantities, clients
            have ownership of individual securities in the percentages and
            ratios recommended by sub-advisers, and in the case of EQIS
            portfolios, EQIS. This gives clients a greater opportunity for broad
            diversification across equity styles, asset classNamees, geographic
            regions, and philosophies with a smaller portion of the assets
            normally required to create the same portfolio in whole shares.
          </p>
          <p>
            <span className="font-weight-bold">
              Pages 1, 2, and 3, if applicable, are the primary pages that
              contain your tax reporting information.
            </span>
            You can also refer to the Gain Loss Report, which is also generally
            available at the end of February. This report is in spreadsheet
            format and can be used to import gain/loss information into tax
            reporting software.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          CAN I IMPORT MY FORM 1099 INTO REPORTING SOFTWARE LIKE TURBOTAX AND
          H&R BLOCK?
        </Accordion.Header>
        <Accordion.Body>
          <p>
            Yes. You can import the reportable data from your Form 1099(s)
            directly into tax reporting packages including TurboTax and H&amp;R
            Block by using their “import from brokerage/financial institution”
            function. The following types of tax information can be imported:
          </p>
          <ul>
            <li>1099-DIV (Dividends and Distributions)</li>
            <li>1099-INT (Interest Income)</li>
            <li>1099-MISC (Miscellaneous Income)</li>
            <li>
              1099-B (Proceeds from Broker and Barter Exchange Transactions)
            </li>
            <li>1099-OID (Original Issue Discount)</li>
          </ul>
          <p>
            Please note that the information found on your Form 1099-R cannot be
            imported at this time. You will need to enter this information
            manually. For detailed steps, view the{" "}
            <a className="download-links" href={taxSeasonGuideUrls[1]}>
              Importing Data into Tax Reporting Software
            </a>{" "}
            guide.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>WHAT IS FORM 8949?</Accordion.Header>
        <Accordion.Body>
          <p>
            Form 8949 is a listing of your long and short term, reportable and
            non-reportable capital gains on taxable accounts. We may generate
            four versions of IRS Form 8949, reporting sales of securities with
            short and long-term capital gains, further subdivided into sales
            with cost basis reported to the IRS (Box A), and sales without cost
            basis or sales where cost basis is not reported to the IRS (Box B).
            Each of the four versions of this Form is necessary to complete
            Schedule D of your tax return, along with any corrected versions
            that may be generated.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>WHAT IS FORM 1099-R?</Accordion.Header>
        <Accordion.Body>
          <p>
            Form 1099-R is an IRS form that reports your distributions from
            profit-sharing plans, retirement plans, IRAs, and pensions. You will
            also receive a 1099-R for any rollovers, Roth conversions, and
            recharacterizations made to your Traditional, Roth, SEP, SIMPLE and
            Beneficiary IRAs.
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default TaxQuestions;

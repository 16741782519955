import axios from "axios";

const UsernameApi = async () => {
  const searchUrl = `${process.env.REACT_APP_TAX_CENTER_API_URL}/taxcenter/username/`;

  console.log(`${searchUrl}`);

  return await axios.get(`${searchUrl}`, {
    withCredentials: true,
  });
};

export default UsernameApi;

import React from "react";
import { ApplyAccountStyling } from "./show-hide-toggler";

const GainslossAccountTitleFormat = (args) => {
  return args.account.AccountType ? (
    <h4
      className={`text-left ${ApplyAccountStyling(
        args.account.AccountNumber,
        args.selectedaccount
      )}`}
      id={args.account.AccountNumber}
      key={`h4_account_title_${args.account.AccountNumber}`}
    >
      {args.account.AccountType} - {args.account.AccountNumber} -{" "}
      {args.account.AccountName}
    </h4>
  ) : (
    <h4
      className="text-left"
      key={`h4_account_title_${args.account.AccountNumber}`}
    >
      {args.account.AccountNumber} - {args.account.AccountName}
    </h4>
  );
};

export default GainslossAccountTitleFormat;

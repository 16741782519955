import "./accounts-search.css";
import useGainsLossTabState from "../../state/useGainsLossTabState";

import GainsLossView from "../../tabs/gains-loss/gains-loss-view-master";

const TaxableAccountsSearchResultsRow = () => {
  const selectedAccountClass =
    "list-group-item list-group-item-secondary list-group-item-action flex-column align-items-start active";
  const notSelectedAccountClass =
    "list-group-item list-group-item-action flex-column align-items-start";

  const {
    setIsAccountSelected,
    accountSelectedId,
    setAccountSelectedId,
    taxableAccounts,
    toggleGainsLossModal,
    setToggleGainsLossModal,
    taxableAccountsSelectedClientId
  } = useGainsLossTabState();

  const handleSelectedAccount = (accountId) => {
    setToggleGainsLossModal(true);
    setIsAccountSelected(true);
    setAccountSelectedId(accountId);
  };

  return (
    <>
      <GainsLossView
        show={toggleGainsLossModal}
        accountId={accountSelectedId}
        clientId={taxableAccountsSelectedClientId}
      />
      <div className="row">
        <div className="col input-group mt-1">
          <div className="taxable-accounts-list" id="accounts-list" data-test="taxable-accounts-list">
            {taxableAccounts.map((c) => (
              <span
                key={c.accountNo}
                onClick={() => handleSelectedAccount(c.accountNo)}
                data-toggle="list"
                className={
                  c.accountNo === accountSelectedId
                    ? selectedAccountClass
                    : notSelectedAccountClass
                }
              >
                <div
                  className="d-flex w-100 justify-content-between"
                  key={`${c.accountNo}_{index+ 2}`}
                >
                  <h6 className="mb-1">
                    {c.accountNo}
                    <span className="h6" style={{ fontWeight: "normal" }}>
                      {" "}
                      {c.accountName}
                    </span>
                  </h6>
                </div>

                <span style={{ fontSize: "10pt" }}>{c.accountType} </span>
                <span style={{ fontSize: "8pt" }}>
                  <br />
                  <span className="fst-italic">{c.accountSubType}</span>
                </span>
              </span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxableAccountsSearchResultsRow;

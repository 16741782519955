import React from "react";
import { default as appMetaData } from "../../package.json";
import turboTaxLogo from "../images/turbotax-logo.png";
import hrBlockLogo from "../images/hrblock-logo.jpg";
import taxSlayerLogo from "../images/taxslayer-logo.png";
import taxActLogo from "../images/taxact-logo.png";
import TaxSoftwareVendor from "./tax-software-vendor";
import "./footer.css";

function Footer() {
  return (
    <>
      <div className="row pb-5 pt-3">
        <div className="col small">
          The information contained herein is provided for informational
          purposes only. EQIS Capital Management, Inc. does not provide tax or
          legal advice. Due to the complexity of tax regulations and the
          individual circumstances of our clients we recommend that you consult
          a tax advisor.
        </div>
      </div>

      <div className="row bg-light pt-3">
        <div className="col h3 pb-5" data-test="footer-title">
          Tax Preparation Software
        </div>
      </div>

      <div className="row bg-light pt-0 pb-4">
        <div className="col-3">
          <TaxSoftwareVendor
            vendorUrl="https://turbotax.intuit.com"
            vendorName="Turbotax"
            imageSource={turboTaxLogo}
            width="165"
            height="78"
          />
        </div>
        <div className="col-3">
          <TaxSoftwareVendor
            vendorUrl="https://www.hrblock.com"
            vendorName="H & R Block"
            imageSource={hrBlockLogo}
            width="102"
            height="102"
          />
        </div>
        <div className="col-3">
          <TaxSoftwareVendor
            vendorUrl="https://www.taxslayer.com"
            vendorName="Tax Slayer"
            imageSource={taxSlayerLogo}
            width="175"
            height="54"
          />
        </div>
        <div className="col-3">
          <TaxSoftwareVendor
            vendorUrl="https://www.taxact.com"
            vendorName="Tax Act"
            imageSource={taxActLogo}
            width="172"
            height="42"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col small">
          &#169; 2004-{new Date().getFullYear()} EQIS Capital. All Rights
          Reserved.{" | "} {appMetaData.name} | version {appMetaData.version}
          {process.env.REACT_APP_ENVIRONMENT !== "PRODUCTION" &&
            ` | ${process.env.REACT_APP_ENVIRONMENT.toLowerCase()}`}
        </div>
      </div>
    </>
  );
}

export default Footer;

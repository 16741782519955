import ClientSearch from "../../search/clients/client-search-form";
import TaxDocumentsSearchForm from "./tax-statements-search-form";
import TaxStatementsSearchResultsRow from "./tax-statements-search-results-row";
import useTaxStatementsTabState from "../../state/useTaxStatementsTabState";
import useSharedContext from "../../state/useSharedContext";

const TaxStatementTab = () => {
  const { taxStatementsSearchCompleteState, taxStatementsCount } =
    useTaxStatementsTabState();
  const { context } = useSharedContext();
  return (
    <>
      <div className="row">
        <div className="col-6">
          <div
            className="border border-secondary rounded p-3 bg-light"
            style={{ height: "400px" }}
          >
            <ClientSearch context={context} />
            <TaxDocumentsSearchForm />
          </div>
        </div>
        <div className="col-6">
          <div
            className="border border-secondary rounded p-3 bg-light"
            style={{ height: "400px" }}
          >
            {taxStatementsSearchCompleteState && (
              <>
                <div className="d-flex justify-content-end p-2">
                  Tax Statements ({taxStatementsCount})
                </div>
                <TaxStatementsSearchResultsRow />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxStatementTab;

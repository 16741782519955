import "./taxcenter-tabs.css";

import DeliveryDatesAndDeadlines from "./tax-deadlines-tab";
import TaxQuestions from "./tax-questions-tab";
import TaxStatementTab from "./tax-statements/tax-statements-tab";
import GainsLossTab from "./gains-loss/gains-loss-tab";
import useSharedMessenger from "../state/useSharedMessenger";
import useSharedContext from "../state/useSharedContext";
import React from "react";

const TaxCenterTabs = () => {
  const { setMessage } = useSharedMessenger();
  const { context, setContext } = useSharedContext();

  const handleTaxStatementsTabClick = () => {
    setContext("tax-statements");
    setMessage({
      text: "",
      level: "",
    });
  };

  const handleGainsLossTabClick = () => {
    setContext("gains-loss");
    setMessage({
      text: "",
      level: "",
    });
  };
  console.log(context);

  return (
    <React.Fragment>
      <ul
        className="nav nav-tabs taxCenterTabs"
        id="taxStatements"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="tax-statements-tab"
            data-bs-toggle="tab"
            data-bs-target="#tax-statements-tab-content"
            data-test="tax-statements-tab"
            type="button"
            role="tab"
            aria-controls="tax-statements-tab"
            aria-selected="true"
            onClick={handleTaxStatementsTabClick}
          >
            Tax Statements
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="gains-loss-tab"
            data-bs-toggle="tab"
            data-bs-target="#gains-loss-tab-content"
            data-test="gains-loss-tab"
            type="button"
            role="tab"
            aria-controls="gains-loss-tab-content"
            aria-selected="false"
            onClick={handleGainsLossTabClick}
          >
            Gains Loss
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="delivery-dates-tab"
            data-bs-toggle="tab"
            data-bs-target="#delivery-dates-tab-content"
            data-test="delivery-dates-tab"
            type="button"
            role="tab"
            aria-controls="delivery-dates-tab-content"
            aria-selected="false"
          >
            Delivery Dates and Deadlines
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="tax-questions-tab"
            data-bs-toggle="tab"
            data-bs-target="#tax-questions-tab-content"
            data-test="tax-questions-tab"
            type="button"
            role="tab"
            aria-controls="tax-questions-tab-content"
            aria-selected="false"
          >
            Tax Questions and Tools
          </button>
        </li>
      </ul>

      <div className="tab-content" id="taxcenter-tabs">
        <div
          className="tab-pane fade show active"
          id="tax-statements-tab-content"
          role="tabpanel"
          aria-labelledby="tax-statements-tab"
        >
          <TaxStatementTab />
        </div>
        <div
          className="tab-pane fade"
          id="gains-loss-tab-content"
          role="tabpanel"
          aria-labelledby="gains-loss-tab"
        >
          <GainsLossTab />
        </div>
        <div
          className="tab-pane fade"
          id="delivery-dates-tab-content"
          role="tabpanel"
          aria-labelledby="delivery-dates-tab"
        >
          <DeliveryDatesAndDeadlines />
        </div>
        <div
          className="tab-pane fade"
          id="tax-questions-tab-content"
          role="tabpanel"
          aria-labelledby="tax-questions-tab"
        >
          <TaxQuestions />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TaxCenterTabs;

import { useState, useEffect } from "react";
import useSharedMessenger from "../../state/useSharedMessenger";
import useTaxStatementsTabState from "../../state/useTaxStatementsTabState";
import TaxDocumentsApi from "./tax-statements-search-api";
import { RotatingLines } from "react-loader-spinner";

const TaxDocumentsSearchForm = () => {
  const [errorState, setErrorState] = useState(false);
  const [taxStatementsSearchStartState, setTaxStatementsSearchStartState] =
    useState(false);

  /** shared state */
  const { setMessage } = useSharedMessenger();
  const {
    isTaxStatementsClientSelected,
    taxStatementsSelectedClientId,
    setTaxDocuments,
    setTaxStatementsCount,
    setTaxStatementsSearchCompleteState,
  } = useTaxStatementsTabState();

  useEffect(() => {
    if (errorState) {
      setTaxStatementsSearchCompleteState(true);
      setTaxStatementsSearchStartState(false);
      setErrorState(false); //reset for the next one!
    }
  }, [errorState, setTaxStatementsSearchCompleteState]);

  const fetchTaxStatements = async () => {
    setTaxStatementsSearchStartState(true);
    setTaxStatementsSearchCompleteState(false);
    setTaxStatementsCount(0);
    setMessage({
      text: `Searching Tax Statements...`,
      level: "info",
    });

    TaxDocumentsApi(taxStatementsSelectedClientId)
      .then((response) => {
        const statementsCount = countTaxStatements(response.data);

        setTaxDocuments(response.data);
        setTaxStatementsSearchCompleteState(true);
        setTaxStatementsSearchStartState(false);
        setTaxStatementsCount(statementsCount);
        if (statementsCount === 0) {
          setMessage({
            text: `No tax statements found`,
            level: "warning",
          });
        } else if (statementsCount === 1) {
          setMessage({
            text: `1 Tax statement found`,
            level: "success",
          });
        } else {
          setMessage({
            text: `${statementsCount} Tax statements found`,
            level: "success",
          });
        }
      })
      .catch((error) => {
        setErrorState(true);
        console.log(error.message);
        if (error.message && error.message === "Network Error") {
          var log = "Tax center session expired. Please login.";
          console.log(log);
          setMessage({
            text: `${log}`,
            level: "sessionexpired",
          });
        }
        if (error.response) {
          setMessage({
            text: "Error retrieving tax documents for this client. Please try again",
            level: "warning",
          });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  const countTaxStatements = (apiResultsData) => {
    var statementCount = 0;
    apiResultsData.map((statement) =>
      statement.map(() => (statementCount += 1))
    );
    return statementCount;
  };

  return (
    <div className="row">
      <div className="col">
        {isTaxStatementsClientSelected && (
          <button
            id="button-fetch-statements"
            className="btn btn-primary float-end text-end"
            type="button"
            onClick={() => fetchTaxStatements()}
            data-test="button-fetch-statements"
          >
            Search Tax Statements
            {taxStatementsSearchStartState && (
              <span id="document-search-spinner" className="ms-2">
                <RotatingLines
                  width="20"
                  strokeColor="white"
                  strokeWidth="4"
                  animationDuration="3"
                />
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default TaxDocumentsSearchForm;

import React from "react";
import CurrencyFormat from "react-currency-format";

const RealizedGainsLossView = ({ account }) => {
  return (
    <>
      <header className="row" role="row">
        <div className="col-3" role="gridcell"></div>
        <div className="col-2" role="gridcell">
          Total Proceeds
        </div>
        <div className="col-2" role="gridcell">
          Cost Basis
        </div>
        <div className="col-2" role="gridcell">
          Disallowed Loss
        </div>
        <div className="col-3" role="gridcell">
          Realized Gain or (Loss)
        </div>
      </header>
      <div
        className="row data-row"
        role="row"
        aria-label="Short Term Gain/Loss"
      >
        <header className="col-3" role="gridcell">
          Short Term Gain/Loss
        </header>
        <div className="col-2" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.shortTermProceeds}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-2" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.shortTermCostBasis}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-2" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.shortTermDisallowedLoss}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.shortTermNetGainLoss}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      </div>
      <div className="row data-row" role="row" aria-label="Long Term Gain/Loss">
        <header className="col-3" role="gridcell">
          Long Term Gain/Loss
        </header>
        <div className="col-2" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.longTermProceeds}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-2" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.longTermCostBasis}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-2" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.longTermDisallowedLoss}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.longTermNetGainLoss}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      </div>

      {account.realizedGainLoss.unknownCostBasisAmount > 0 && (
        <div className="row data-row" role="row" aria-label="Unknown Basis">
          <header className="col-3" role="gridcell">
            Unknown basis
          </header>
          <div className="col-2" role="gridcell">
            <CurrencyFormat
              value={account.realizedGainLoss.unknownCostBasisAmount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
            />
          </div>
          <div className="col-2" role="gridcell">
            -
          </div>
          <div className="col-2" role="gridcell">
            -
          </div>
          <div className="col-3" role="gridcell">
            <CurrencyFormat
              value={account.realizedGainLoss.unknownCostBasisAmount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
            />
          </div>
        </div>
      )}

      <div className="row totals" role="row" aria-label="Net Totals">
        <header className="col-3" role="gridcell">
          Net Totals
        </header>
        <div className="col-2" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.totalRealizedProceeds}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-2" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.totalRealizedCostBasis}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-2" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.totalRealizedDisallowedLoss}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
        <div className="col-3" role="gridcell">
          <CurrencyFormat
            value={account.realizedGainLoss.totalRealizedNetGainLoss}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </div>
      </div>
    </>
  );
};

export default RealizedGainsLossView;

import { useState, useEffect } from "react";
import "./message-panel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTriangleExclamation,
  faPersonToDoor,
  faCircleInfo,
  faCircleCheck,
  faHexagonExclamation,
} from "@fortawesome/pro-solid-svg-icons";

import useSharedUserSession from "../state/useSharedUserSession";
import WrapLoginLink from "../user/login-link";

const MessagePanel = (props) => {
  const [messageClassName, setMessageClassName] = useState(
    "alert alert-secondary"
  );
  const [iconClassName, setIconClassName] = useState("");
  const [blankMessageFlag, setBlankMessageFlag] = useState(false);
  const [isMessagePanelVisible, setIsMessagePanelVisible] = useState(false);

  /** shared state */
  const { setActive } = useSharedUserSession();

  useEffect(() => {
    setIsMessagePanelVisible(props.level.length > 0);
    if (props.level === "") {
      setBlankMessageFlag(true);
    } else {
      setBlankMessageFlag(false);
    }

    if (props.level === "sessionexpired") {
      setMessageClassName("alert alert-warning");
      setActive(false);
      setIconClassName(faPersonToDoor);
    } else {
      setActive(true);
    }

    if (props.level === "info") {
      setMessageClassName("alert alert-info");
      setIconClassName(faCircleInfo);
    }
    if (props.level === "warning") {
      setMessageClassName("alert alert-warning");
      setIconClassName(faTriangleExclamation);
    }

    if (props.level === "success") {
      setMessageClassName("alert alert-success fadeOut");
      setIconClassName(faCircleCheck);
    }

    if (props.level === "danger") {
      setMessageClassName("alert alert-danger");
      setIconClassName(faHexagonExclamation);
    } else if (blankMessageFlag) {
      setMessageClassName("alert alert-secondary");
    }
  }, [props, blankMessageFlag, setActive]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          {isMessagePanelVisible && (
            <div
              id="search-message-panel"
              className={messageClassName}
              data-test="message-panel"
            >
              {!blankMessageFlag && (
                <span className="icon">
                  <FontAwesomeIcon icon={iconClassName} size="2x" />
                </span>
              )}

              <span id="search-message-text" className="align-middle">
                {props.level === "sessionexpired"
                  ? WrapLoginLink(props.text)
                  : props.text}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MessagePanel;

import { useState } from "react";
import useSharedUserSession from "../state/useSharedUserSession";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RotatingLines } from "react-loader-spinner";

const Header = () => {
  /* local state */
  const [isLoggingIn] = useState(false);
  /** shared state */
  const { userName, active } = useSharedUserSession();

  const ShowLoginWindow = () => {
    window.open(process.env.REACT_APP_TAMP_LOGIN_URL, "_blank");
  };
  return (
    <>
      <div className="row justify-content-md-center">
        <div className="col text-end pr-5 pt-3">
          {active ? (
            <>
              <span className="p-2">
                <FontAwesomeIcon icon={duotone("user")} size="1x" />
              </span>
              <span className="text-secondary">{userName}</span>
            </>
          ) : (
            <>
              <div className="btn-group" role="group">
                {isLoggingIn ? (
                  <>
                    <button type="button" className="btn btn-secondary">
                      Logging In{" "}
                      <span id="accounts-search-spinner" className="ms-2">
                        <RotatingLines
                          width="25"
                          strokeColor="white"
                          strokeWidth="4"
                          animationDuration="3"
                        />
                      </span>
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => ShowLoginWindow()}
                  >
                    <FontAwesomeIcon icon={solid("person-to-door")} size="1x" />
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-sm text-center">
          <span className="eqisNameTaxCenterTitle">EQIS</span>{" "}
          <span className="taxCenterTitle">Tax Center</span>
        </div>
      </div>
      <div className="row pb-5">
        <div className="col-sm-12 text-center">
          Provided for information purposes only. Consult your tax advisor.
        </div>
      </div>
    </>
  );
};
export default Header;

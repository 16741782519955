import React from "react";

const DeliveryDatesAndDeadlines = () => (
  <React.Fragment>
    <div className="row pb-3">
      <div className="col">
        <div className="font-weight-bold h3" data-test="tax-deadlines-title">
          Important Tax Information and IRA Deadlines
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12 mx-auto font-weight-bold h4">
        IRA Deadlines
      </div>
    </div>

    <div className="row pb-3 headerRow">
      <div className="col">
        <div className="row text-white pb-2 pt-2">
          <div className="col-4"></div>
          <div className="col-2">Date</div>
          <div className="col-6">Information</div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-4 border-left border-right border-bottom border-dark font-weight-bold">
       2022 Contribution Deadline
      </div>
      <div className="col-2 border-right border-bottom border-dark">
        04/18/2023
      </div>
      <div className="col-6 border-right border-bottom border-dark">
        <p>
        Applies to Traditional and ROTH IRAs. Contributions received 1/1 - 4/18/23 
        will be applied to tax year 2023 unless indicated otherwise
        </p>
      </div>
    </div>

    <div className="row">
      <div className="col-4 border-left border-right border-bottom border-dark font-weight-bold">
        2022 IRA Recharacterization
      </div>
      <div className="col-2 border-bottom border-right border-dark">
        10/16/2023
      </div>
      <div className="col-6 border-bottom border-right border-dark">
        <p>
        Assets must be settled and the IRA distribution form must be received by this date
        </p>
      </div>
    </div>

    <div className="row pt-3">
      <div className="col font-weight-bold h4">
        Tax Documents Delivery Schedule
      </div>
    </div>

    <div className="row pb-3">
      <div className="col">
        <div className="row text-white pb-2 pt-2 headerRow">
          <div className="col-6">Document</div>
          <div className="col-6">Lastet Delivery Date</div>
        </div>

        <div className="row">
          <div className="col-6 border-left border-right border-bottom border-dark font-weight-bold">
            1099-R
          </div>
          <div className="col-6 border-right border-bottom border-dark">
            Late January to early February
          </div>
        </div>

        <div className="row">
          <div className="col-6 border-left border-right border-bottom border-dark font-weight-bold">
            Consolidated 1099
          </div>
          <div className="col-6 border-bottom border-right border-dark">
           Late January to early March
          </div>
        </div>

        <div className="row">
          <div className="col-6 border-left border-right border-bottom border-dark font-weight-bold">
            5498
          </div>
          <div className="col-6 border-bottom border-right border-dark">
            End of May
          </div>
        </div>
      </div>
    </div>
    <div>
      <p>
        Corrections to IRS Form 1099 are issued when companies reclassify
        distributions from the prior year. Reclassifications and the
        associated corrections to IRS Form 1099, Form 8949, downloads and
        gain-loss reports are typically completed by the end of March but
        may be posted at any time.
      </p>  
    </div>
  </React.Fragment>
);

export default DeliveryDatesAndDeadlines;

const ApplyAccountStyling = (accountNumber, selectedAccountNumber) => {
  return accountNumber === selectedAccountNumber ||
    selectedAccountNumber === "All Accounts"
    ? "selected"
    : "";
};

const ApplyNavbarPdfButtonStyling = (accountNumber, selectedAccountNumber) => {
  return accountNumber === selectedAccountNumber ||
    selectedAccountNumber === "All Accounts"
    ? "selected"
    : "";
};

const ApplyNavbarExportTaxLotsStyling = (
  accountNumber,
  selectedAccountNumber
) => {
  return accountNumber === selectedAccountNumber ? "selected" : "";
};

export {
  ApplyAccountStyling,
  ApplyNavbarExportTaxLotsStyling,
  ApplyNavbarPdfButtonStyling,
};

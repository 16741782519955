import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./client-search.css";

import useTaxStatementsTabState from "../../state/useTaxStatementsTabState";
import useGainsLossTabState from "../../state/useGainsLossTabState";
import useSharedMessenger from "../../state/useSharedMessenger";
import useSharedContext from "../../state/useSharedContext";

import MessagePanel from "../../messaging/message-panel";
import ClientList from "./client-search-api";
import ClientSearchResultsRow from "./client-search-results-row";

const ClientSearch = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchSpinnerFlag, setShowSearchSpinnerFlag] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [searchClearState, setSearchClearState] = useState(true);
  const [searchStartState, setSearchStartState] = useState(false);
  const [searchCompleteState, setSearchCompleteState] = useState(false);

  const {
    setIsTaxStatementsClientSelected,
    setTaxStatementsSelectedClientId,
    setTaxDocuments,
    setTaxStatementsCount,
  } = useTaxStatementsTabState();
  const {
    setIsTaxableAccountsClientSelected,
    setTaxableAccountsSelectedClientId,
    setTaxableAccounts,
    setAccountSelectedId,
  } = useGainsLossTabState();
  const { message, setMessage } = useSharedMessenger();
  const { context } = useSharedContext();

  useEffect(() => {
    if (searchClearState) {
      setTaxDocuments([]);
      setSearchCompleteState(false);
      setSearchStartState(false);
      setSearchQuery("");
      setSearchResults([]);
      setTaxDocuments([]);
      setTaxStatementsCount(0);
      setMessage({
        level: "",
      });
      if (context === "gains-loss") {
        setIsTaxableAccountsClientSelected(false);
        setTaxableAccounts([]);
      } else {
        setIsTaxStatementsClientSelected(false);
        setTaxDocuments([]);
      }
      setSearchClearState(false); //Important to reset state
    }
  }, [
    searchClearState,
    searchQuery,
    setSearchCompleteState,
    setMessage,
    setIsTaxableAccountsClientSelected,
    setIsTaxStatementsClientSelected,
    setTaxableAccounts,
    setTaxDocuments,
    setTaxStatementsCount,
    context,
  ]);

  useEffect(() => {
    if (errorState) {
      setShowSearchSpinnerFlag(false);
      setErrorState(false); //reset for the next one!
    }
  }, [errorState]);

  useEffect(() => {
    if (searchCompleteState) {
      setSearchStartState(false);
      setShowSearchSpinnerFlag(false);
    }
  }, [searchCompleteState]);

  useEffect(() => {
    if (searchStartState) {
      setSearchCompleteState(false);
      setSearchResults([]);
      setTaxDocuments([]);
      setTaxStatementsCount(0);
      setShowSearchSpinnerFlag(true);

      if (context === "gains-loss") {
        setIsTaxableAccountsClientSelected(false);
        setTaxableAccountsSelectedClientId(0);
        setAccountSelectedId(0);
      } else {
        setIsTaxStatementsClientSelected(false);
        setTaxStatementsSelectedClientId(0);
      }
    }
  }, [
    searchStartState,
    setIsTaxStatementsClientSelected,
    setTaxStatementsSelectedClientId,
    setIsTaxableAccountsClientSelected,
    setTaxableAccountsSelectedClientId,
    setAccountSelectedId,
    setTaxDocuments,
    setTaxStatementsCount,
    context,
  ]);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const searchCriteriaInput = useRef(null);

  const handleClearSearch = (e) => {
    setSearchClearState(true);

    searchCriteriaInput.current.focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchQuery.length > 40) {
      setMessage({
        text: `You have entered more than the 50 character limit (${searchQuery.length}). Please shorten and try again.`,
        level: "warning",
      });
      setErrorState(true);
      return false;
    }
    const regex = new RegExp(/^[a-zA-Z\-'\s]+$/);

    if (!regex.test(searchQuery)) {
      console.log("regex failed");
      setMessage({
        text: "Search must contain characters (A-Z), single quotes (') and dashes (-).",
        level: "warning",
      });
      setErrorState(true);
      return false;
    }

    if (searchQuery.length <= 2) {
      setMessage({
        text: `Please enter at least 3 characters to search.`,
        level: "warning",
      });
      setErrorState(true);
      return false;
    }

    if (searchQuery.length >= 3) {
      setSearchStartState(true);
      setMessage({ text: `Searching "${searchQuery}"...`, level: "info" });

      ClientList(searchQuery)
        .then((response) => {
          setSearchResults(response.data);
          setSearchCompleteState(true);
          if (response.data.length === 0) {
            setMessage({
              text: `No clients named "${searchQuery}" found .`,
              level: "warning",
            });
          } else if (response.data.length === 1) {
            setMessage({
              text: `Found ${response.data.length} client`,
              level: "success",
            });
          } else {
            setMessage({
              text: `Found ${response.data.length} clients`,
              level: "success",
            });
          }
        })
        .catch((error) => {
          setErrorState(true);
          console.log(error.message);
          if (error.message && error.message === "Network Error") {
            var log = "Tax center session expired. Please Login.";
            console.log(log);
            setMessage({
              text: `${log}`,
              level: "sessionexpired",
            });
          }
          if (error.response) {
            var messageText;
            if (error.response.status === 400 && searchQuery.length > 40) {
              messageText =
                "Your search must be less than 40 characters long. Please try again";
            } else {
              console.log(error.toJSON());
              messageText = "Invalid search, please try again";
            }

            setMessage({
              text: messageText,
              level: "warning",
            });
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    }
  };

  return (
    <>
      <MessagePanel {...message} />

      <form id="client-search-form" autoComplete="off" onSubmit={handleSubmit}>
        <div className="row mt-3">
          <div className="col">
            <div className="input-group">
              <span
                className="input-group-text btn-secondary"
                id="client-search-icon"
              >
                <FontAwesomeIcon
                  icon={solid("folder-magnifying-glass")}
                  size="1x"
                />
              </span>

              <input
                type="text"
                className="form-control place_holder"
                id={`search-criteria-input-${context}`}
                ref={searchCriteriaInput}
                value={searchQuery}
                onChange={handleChange}
                placeholder="enter client name : press enter"
                autoFocus
                data-test={`search-criteria-input-${context}`}
              />

              <button
                className="btn btn-secondary btn-sm"
                type="button"
                onClick={handleClearSearch}
              >
                <FontAwesomeIcon icon={solid("delete-left")} size="1x" />
              </button>

              {showSearchSpinnerFlag && (
                <span id="search-spinner">
                  <RotatingLines
                    width="25"
                    strokeColor="black"
                    strokeWidth="4"
                    animationDuration="3"
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        {searchCompleteState && (
          <ClientSearchResultsRow
            searchResults={searchResults}
            context={context}
          />
        )}
      </form>
    </>
  );
};

export default ClientSearch;

import React from "react";

const CheckbackLaterMessage = () => {
  return (
    <div className="alert alert-eqis-light text-center" role="alert">
      Data is currently unavailable. Please check back later.
    </div>
  );
};

const DataUnavailableMessage = () => {
  return (
    <div className="alert alert-eqis-light text-center" role="alert">
      Unavailable
    </div>
  );
};

export { CheckbackLaterMessage, DataUnavailableMessage };

import { RotatingLines } from "react-loader-spinner";
import useSharedDownloadingState from "../../common/useSharedDownloadingState";

const DocumentDownloadSpinner = () => {
  /** shared state */
  const { downloadingMessage } = useSharedDownloadingState();

  return (
    <div className="justify-content-center">
      <div
        className="alert alert-primary d-flex align-items-center"
        role="alert"
      >
        <div>
          <span className=" lead p-3">{downloadingMessage}</span>
          <RotatingLines
            width="40"
            strokeColor="black"
            strokeWidth="4"
            animationDuration="3"
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentDownloadSpinner;

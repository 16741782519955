import { useState } from "react";
import useTaxStatementsTabState from "../../state/useTaxStatementsTabState";
import useGainsLossTabState from "../../state/useGainsLossTabState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

const ClientSearchResultsRow = ({ searchResults, context }) => {
  const selectedClientClass =
    "list-group-item list-group-item-secondary list-group-item-action flex-column align-items-start active";
  const notSelectedClientClass =
    "list-group-item list-group-item-action flex-column align-items-start";
  const [selectedClientId, setSelectedClientId] = useState(0);
  const {
    setIsTaxableAccountsClientSelected,
    setTaxableAccountsSelectedClientId,
    setTaxableAccounts,
  } = useGainsLossTabState();

  const { setIsTaxStatementsClientSelected, setTaxStatementsSelectedClientId } =
    useTaxStatementsTabState();

  const handleSelectedClient = (clientId) => {
    setSelectedClientId(clientId);
    if (context === "gains-loss") {
      setTaxableAccounts([]);
      setIsTaxableAccountsClientSelected(true);
      setTaxableAccountsSelectedClientId(clientId);
    }
    if (context === "tax-statements") {
      setIsTaxStatementsClientSelected(true);
      setTaxStatementsSelectedClientId(clientId);
    }
  };

  return (
    <div className="row">
      <div className="col input-group mt-1">
        <div
          className="client-list"
          id={`client-list-${context}`}
          data-test={`client-list-${context}`}
        >
          {searchResults.map((c) => (
            <span
              key={c.contactID}
              onClick={() => handleSelectedClient(c.contactID)}
              data-toggle="list"
              className={
                c.contactID === selectedClientId
                  ? selectedClientClass
                  : notSelectedClientClass
              }
            >
              <div className="d-flex w-100 justify-content-between">
                <h6 className="mb-1">
                  <span style={{ textTransform: "uppercase" }}>
                    {" "}
                    {c.primaryLastName}
                  </span>
                  , {c.primaryFirstName}
                </h6>
              </div>
              <span className="icon">
                {<FontAwesomeIcon icon={duotone("mobile-screen")} />}
              </span>{" "}
              <span style={{ fontSize: "10pt" }}>{c.phoneDay}</span>{" "}
              <span className="icon pl-3 pr-1">
                {<FontAwesomeIcon icon={duotone("envelope")} />}
              </span>
              <span style={{ fontSize: "10pt" }}> {c.email1}</span>
              <span style={{ fontSize: "8pt" }}>
                <br /> advisor: <span className=" fst-italic">{c.advisor}</span>
              </span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientSearchResultsRow;

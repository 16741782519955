import { useState, useEffect } from "react";
import useSharedMessenger from "../../state/useSharedMessenger";
import useGainsLossTabState from "../../state/useGainsLossTabState";
import TaxableAccountsApi from "./taxable-accounts-search-api";
import { RotatingLines } from "react-loader-spinner";

const TaxableAccountsSearchForm = () => {
  /** local state */
  const [errorState, setErrorState] = useState(false);
  const [taxableAccountsSearchStartState, setTaxableAccountsSearchStartState] =
    useState(false);

  /** shared state */
  const { setMessage } = useSharedMessenger();
  const {
    isTaxableAccountsClientSelected,
    taxableAccountsSelectedClientId,
    setTaxableAccounts,
    setTaxableAccountsSearchCompleteState,
    setAccountSelectedId,
  } = useGainsLossTabState();

  useEffect(() => {
    if (errorState) {
      setTaxableAccountsSearchCompleteState(true);
      setTaxableAccountsSearchStartState(false);
      setErrorState(false); //reset for the next one!
    }
  }, [errorState, setTaxableAccountsSearchCompleteState]);

  const fetchTaxableAccounts = async () => {
    setTaxableAccounts([]);
    setTaxableAccountsSearchStartState(true);
    setAccountSelectedId(0);

    TaxableAccountsApi(taxableAccountsSelectedClientId)
      .then((response) => {
        setTaxableAccounts(response.data);
        setTaxableAccountsSearchCompleteState(true);
        setTaxableAccountsSearchStartState(false);
        var accountsFound = response.data.length;
        if (accountsFound === 0) {
          setMessage({
            text: `No taxable accounts found`,
            level: "warning",
          });
        } else if (accountsFound === 1) {
          setMessage({
            text: "1 Taxable account found",
            level: "success",
          });
        } else {
          setMessage({
            text: `${accountsFound} Taxable accounts found`,
            level: "success",
          });
        }
      })
      .catch((error) => {
        setErrorState(true);
        console.log(error.message);
        if (error.message && error.message === "Network Error") {
          var log = "Tax center session expired. Please Login.";
          console.log(log);
          setMessage({
            text: `${log}`,
            level: "sessionexpired",
          });
        }
        if (error.response) {
          setMessage({
            text: "Error retrieving tax documents for this client. Please try again",
            level: "warning",
          });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  return (
    <div className="row">
      <div className="col">
        {isTaxableAccountsClientSelected && (
          <button
            id="button-fetch-accounts"
            className="btn btn-primary float-end text-end"
            type="button"
            onClick={() => fetchTaxableAccounts()}
          >
            Search Taxable Accounts
            {taxableAccountsSearchStartState && (
              <span id="accounts-search-spinner" className="ms-2">
                <RotatingLines
                  width="20"
                  strokeColor="white"
                  strokeWidth="4"
                  animationDuration="3"
                />
              </span>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default TaxableAccountsSearchForm;

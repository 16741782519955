import { useEffect } from "react";
import TagManager from "react-gtm-module";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Header from "./header/taxcenter-header";
import Tabs from "./tabs/taxcenter-tabs";
import Footer from "./footer/taxcenter-footer";
import useSharedUserSession from "./state/useSharedUserSession";
import UsernameApi from "./user/username-api";

const TaxCenter = () => {
  /** shared state */
  const { active, setActive, setUserName } = useSharedUserSession();

  window.location = "https://tax-center.freedomadvisors.com/";

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
      TagManager.initialize({ gtmId: "GTM-MFNBVMP" });
    }
  }, []);

  useEffect(() => {
    UsernameApi()
      .then((response) => {
        setUserName(response.data);
        setActive(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [active, setActive, setUserName]);

  return (
    <>
      {active && (
        <div className="container">
          <Header />
          <Tabs />
          <Footer />
        </div>
      )}
    </>
  );
};

export default TaxCenter;

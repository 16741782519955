import React from "react";

const GainsLossDisclosure = () => {
  return (
    <>
      <article
        className="disclosure all selected d-print-none"
        role="complementary"
        aria-label="Disclosures"
      >
        <hr />
        <h5>Important Information</h5>
        <p>
          The realized and unrealized gain and loss information provided is at
          the taxable account level only. Transactions are updated intra-day,
          securities are priced as of the close of the prior trading day.
          Although efforts have been made to assure the accuracy and quality of
          the information, prices and/or dates may be inaccurate, incomplete, or
          subject to change. EQIS makes no warranties with respect to, and
          specifically disclaims any liability arising out of your use of, any
          position taken in reliance upon the cost information provided herein.
          The information is not intended to provide, and should not be relied
          on for, tax, legal or accounting purposes. The information provided is
          prepared by EQIS using information generated by Folio Investments,
          Inc. or other third-party broker-dealers. Consult your tax advisor for
          additional information.
        </p>
        <dl>
          <dt>Value as of</dt>
          <dd>
            Calculated as the aggregate share quantity multiplied by the last
            closing price as related to long or short term gain or loss.
          </dd>
          <dt>Cost basis</dt>
          <dd>
            The original purchase price paid in aggregate, including any
            applicable fees. The cost basis displayed may be adjusted to account
            for events such as stock splits, exchanges, return of capital
            transactions, and wash sales.
          </dd>
          <dt>Unrealized Gain or Loss</dt>
          <dd>
            Reflects the short or long term gain or loss for current holdings
            that have not yet been realized through a closing transaction.
          </dd>
          <dt>Realized Gain or Loss</dt>
          <dd>
            Reflects the aggregate gain or loss on an investment position that
            is sold or closed.
          </dd>
          <dt>Short-term Gain or Loss</dt>
          <dd>
            The aggregate gain or loss of all securities held less than one
            year.
          </dd>
          <dt>Long-term Gain or Loss</dt>
          <dd>
            The aggregate gain or loss of all securities held for more than a
            year (at least one year and one day from the acquisition date).
          </dd>
          <dt>Unknown Basis</dt>
          <dd>
            Cost basis may not be known if an uncovered lot is transferred in
            from other financial institutions. If the cost basis is unknown, the
            system will assume a cost basis of zero.
          </dd>
          <dt>Total Proceeds</dt>
          <dd>The amount of money received upon the sale of a security.</dd>
          <dt>Disallowed Loss</dt>
          <dd>
            A loss from the sale of a security that cannot be deducted if the
            same or substantially identical security was purchased 30 days
            before or after the sale. Disallowed losses can be added to the
            price you paid for the repurchased shares. Disallowed losses due to
            wash sale rules for non-covered securities are not identified.
          </dd>
          <dt>Unavailable</dt>
          <dd>
            Will display when the account is held fully in cash, when the gain
            or loss information is temporarily unavailable, when the account has
            a zero or negative balance, or when the account has been closed.
          </dd>
        </dl>
        <aside>
          Advisory services are offered through EQIS Capital Management, Inc.
          (“EQIS”) a registered investment adviser. If you have any questions,
          please contact Client Services by emailing
          <a
            href="mailto:support@EQIScapital.com"
            target="_blank"
            rel="noreferrer"
            className="p-1"
          >
            support@EQIScapital.com
          </a>{" "}
          or calling
          <a href="tel:800-949-9936" className="p-1">
            (800) 949-9936
          </a>
          , extension 2.
        </aside>
      </article>
      <article
        className="disclosure account d-print-none"
        role="complementary"
        aria-label="Disclosures"
      >
        <hr />
        <h5>Important Information</h5>
        <p>
          The realized and unrealized gain and loss information provided is at
          the taxable account level only. Transactions are updated intra-day,
          securities are priced as of the close of the prior trading day.
          Although efforts have been made to assure the accuracy and quality of
          the information, prices and/or dates may be inaccurate, incomplete, or
          subject to change. EQIS makes no warranties with respect to, and
          specifically disclaims any liability arising out of your use of, any
          position taken in reliance upon the cost information provided herein.
          The information is not intended to provide, and should not be relied
          on for, tax, legal or accounting purposes. The information provided is
          prepared by EQIS using information generated by Folio Investments,
          Inc. or other third-party broker-dealers. Consult your tax advisor for
          additional information.
        </p>
        <dl>
          <dt>Value as of</dt>
          <dd>
            Calculated as the aggregate share quantity multiplied by the last
            closing price as related to long or short term gain or loss.
          </dd>
          <dt>Cost basis</dt>
          <dd>
            The original purchase price paid in aggregate, including any
            applicable fees. The cost basis displayed may be adjusted to account
            for events such as stock splits, exchanges, return of capital
            transactions, and wash sales.
          </dd>
          <dt>Unrealized Gain or Loss</dt>
          <dd>
            Reflects the short or long term gain or loss for current holdings
            that have not yet been realized through a closing transaction.
          </dd>
          <dt>Realized Gain or Loss</dt>
          <dd>
            Reflects the aggregate gain or loss on an investment position that
            is sold or closed.
          </dd>
          <dt>Short-term Gain or Loss</dt>
          <dd>
            The aggregate gain or loss of all securities held less than one
            year.
          </dd>
          <dt>Long-term Gain or Loss</dt>
          <dd>
            The aggregate gain or loss of all securities held for more than a
            year (at least one year and one day from the acquisition date).
          </dd>
          <dt>Unknown Basis</dt>
          <dd>
            Cost basis may not be known if an uncovered lot is transferred in
            from other financial institutions. If the cost basis is unknown, the
            system will assume a cost basis of zero.
          </dd>
          <dt>Total Proceeds</dt>
          <dd>The amount of money received upon the sale of a security.</dd>
          <dt>Disallowed Loss</dt>
          <dd>
            A loss from the sale of a security that cannot be deducted if the
            same or substantially identical security was purchased 30 days
            before or after the sale. Disallowed losses can be added to the
            price you paid for the repurchased shares. Disallowed losses due to
            wash sale rules for non-covered securities are not identified.
          </dd>
          <dt>Unavailable</dt>
          <dd>
            Will display when the account is held fully in cash, when the gain
            or loss information is temporarily unavailable, when the account has
            a zero or negative balance, or when the account has been closed.
          </dd>
        </dl>
        <aside>
          Advisory services are offered through EQIS Capital Management, Inc.
          (“EQIS”) a registered investment adviser. If you have any questions,
          please contact Client Services by emailing{" "}
          <a
            href="mailto:support@EQIScapital.com"
            target="_blank"
            rel="noreferrer"
            className="p-1"
          >
            support@EQIScapital.com
          </a>{" "}
          or calling{" "}
          <a href="tel:800-949-9936" className="p-1">
            (800) 949-9936
          </a>
          , extension 2.
        </aside>
      </article>
      <article
        className="disclosure d-none d-print-block text-left"
        role="complementary"
        aria-label="Disclosures"
      >
        <hr />
        <h5>Important Information</h5>
        <p>
          The realized and unrealized gain and loss information provided is at
          the taxable account level only. Transactions are updated intra-day,
          securities are priced as of the close of the prior trading day.
          Although efforts have been made to assure the accuracy and quality of
          the information, prices and/or dates may be inaccurate, incomplete, or
          subject to change. EQIS makes no warranties with respect to, and
          specifically disclaims any liability arising out of your use of, any
          position taken in reliance upon the cost information provided herein.
          The information is not intended to provide, and should not be relied
          on for, tax, legal or accounting purposes. The information provided is
          prepared by EQIS using information generated by Folio Investments,
          Inc. or other third-party broker-dealers. Consult your tax advisor for
          additional information.
        </p>
        <dl>
          <dt>Value as of</dt>
          <dd>
            Calculated as the aggregate share quantity multiplied by the last
            closing price as related to long or short term gain or loss.
          </dd>
          <dt>Cost basis</dt>
          <dd>
            The original purchase price paid in aggregate, including any
            applicable fees. The cost basis displayed may be adjusted to account
            for events such as stock splits, exchanges, return of capital
            transactions, and wash sales.
          </dd>
          <dt>Unrealized Gain or Loss</dt>
          <dd>
            Reflects the short or long term gain or loss for current holdings
            that have not yet been realized through a closing transaction.
          </dd>
          <dt>Realized Gain or Loss</dt>
          <dd>
            Reflects the aggregate gain or loss on an investment position that
            is sold or closed.
          </dd>
          <dt>Short-term Gain or Loss</dt>
          <dd>
            The aggregate gain or loss of all securities held less than one
            year.
          </dd>
          <dt>Long-term Gain or Loss</dt>
          <dd>
            The aggregate gain or loss of all securities held for more than a
            year (at least one year and one day from the acquisition date).
          </dd>
          <dt>Unknown Basis</dt>
          <dd>
            Cost basis may not be known if an uncovered lot is transferred in
            from other financial institutions. If the cost basis is unknown, the
            system will assume a cost basis of zero.
          </dd>
          <dt>Total Proceeds</dt>
          <dd>The amount of money received upon the sale of a security.</dd>
          <dt>Disallowed Loss</dt>
          <dd>
            A loss from the sale of a security that cannot be deducted if the
            same or substantially identical security was purchased 30 days
            before or after the sale. Disallowed losses can be added to the
            price you paid for the repurchased shares. Disallowed losses due to
            wash sale rules for non-covered securities are not identified.
          </dd>
          <dt>Unavailable</dt>
          <dd>
            Will display when the account is held fully in cash, when the gain
            or loss information is temporarily unavailable, when the account has
            a zero or negative balance, or when the account has been closed.
          </dd>
        </dl>
        <aside>
          Advisory services are offered through EQIS Capital Management, Inc.
          (“EQIS”) a registered investment adviser. If you have any questions,
          please contact Client Services by emailing{" "}
          <a
            href="mailto:support@EQIScapital.com"
            target="_blank"
            rel="noreferrer"
            className="p-1"
          >
            support@EQIScapital.com
          </a>{" "}
          or calling &nbsp;
          <a href="tel:800-949-9936" className="p-1">
            (800) 949-9936
          </a>
          , extension 2.
        </aside>
      </article>
    </>
  );
};

export default GainsLossDisclosure;

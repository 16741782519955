export const useFileNameGenerator = () => {
  const generateDocumentFileName = (url, documentType, isSummary) => {
    let fileNamePrefix = "DETAILED-";

    const urlParts = url.split("/");
    if (isSummary) {
      fileNamePrefix = "SUMMARY-";
    }

    if (documentType === "TaxLotCsv") {
      return generateTaxLotFileName(urlParts);
    } else {
      return generateTaxStatementFileName(fileNamePrefix, urlParts);
    }
  };

  const generateTaxStatementFileName = (fileNamePrefix, urlParts) => {
    return fileNamePrefix + urlParts[5] + "-" + urlParts[7] + "-" + urlParts[9];
  };

  const generateTaxLotFileName = (urlParts) => {
    var year = urlParts[urlParts.length - 1].split("=")[1];
    return "TAX-LOT-" + year + "-" + urlParts[3] + "-" + urlParts[5] + ".csv";
  };

  return { generateDocumentFileName };
};

import { useState } from "react";

const useContext = () => {
  const [context, setContext] = useState("tax-statements");

  return {
    context,
    setContext,
  };
};

export default useContext;

import { useState } from "react";

const TaxStatementsTabState = () => {
  const [taxDocuments, setTaxDocuments] = useState([]);
  const [taxStatementsCount,setTaxStatementsCount] = useState(0);
  const [
    taxStatementsSearchCompleteState,
    setTaxStatementsSearchCompleteState,
  ] = useState(false);
  const [taxStatementsSearchStartState, setTaxStatementsSearchStartState] =
    useState(false);
  const [isTaxStatementsClientSelected, setIsTaxStatementsClientSelected] =
    useState(false);
  const [taxStatementsSelectedClientId, setTaxStatementsSelectedClientId] =
    useState(0);

  return {
    taxDocuments,
    setTaxDocuments,
    taxStatementsCount,
    setTaxStatementsCount,
    taxStatementsSearchStartState,
    setTaxStatementsSearchStartState,
    taxStatementsSearchCompleteState,
    setTaxStatementsSearchCompleteState,
    isTaxStatementsClientSelected,
    setIsTaxStatementsClientSelected,
    taxStatementsSelectedClientId,
    setTaxStatementsSelectedClientId,
  };
};

export default TaxStatementsTabState;

const TaxFormNameMapping = function (originalName) {
  let result;

  switch (originalName) {
    case "Tax1099":
      result = "Form 1099";
      break;
    case "Tax1099R":
      result = "Form 1099R";
      break;
    case "Tax8949":
      result = "Form 8949";
      break;
    case "TaxLotCsv":
      result = "Closed Tax Lots";
      break;
    default:
      result = originalName;
  }
  return result;
};

export default TaxFormNameMapping;

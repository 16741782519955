import { useState } from "react";

const GainsLossTabState = () => {
  const [accountSelectedId, setAccountSelectedId] = useState(0);
  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [toggleGainsLossModal, setToggleGainsLossModal] = useState(false);
  const [taxableAccounts, setTaxableAccounts] = useState([]);
  const [isTaxableAccountsClientSelected, setIsTaxableAccountsClientSelected] =
    useState(false);
  const [gainsLossSelectedAccount, setGainsLossSelectedAccount] = useState(0);
  const [
    gainsLossSelectedAccountStyleClass,
    setGainsLossSelectedAccountStyleClass,
  ] = useState("");

  const [
    taxableAccountsSearchCompleteState,
    setTaxableAccountsSearchCompleteState,
  ] = useState(false);

  const [taxableAccountsSelectedClientId, setTaxableAccountsSelectedClientId] =
    useState(0);

  return {
    taxableAccounts,
    setTaxableAccounts,
    taxableAccountsSearchCompleteState,
    setTaxableAccountsSearchCompleteState,
    isTaxableAccountsClientSelected,
    setIsTaxableAccountsClientSelected,
    taxableAccountsSelectedClientId,
    setTaxableAccountsSelectedClientId,
    isAccountSelected,
    setIsAccountSelected,
    accountSelectedId,
    setAccountSelectedId,
    toggleGainsLossModal,
    setToggleGainsLossModal,
    gainsLossSelectedAccount,
    setGainsLossSelectedAccount,
    gainsLossSelectedAccountStyleClass,
    setGainsLossSelectedAccountStyleClass,
  };
};

export default GainsLossTabState;
